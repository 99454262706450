.login {
  background: #1e1e1e;
  border-radius: 16px;
  padding: 40px 57px;
  width: 489px;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 50px;
    margin-bottom: 30px;
    font-family: $secondary-font;
  }
  button {
    height: 48px;
    white-space: nowrap;
  }
  input {
    margin-bottom: 15px;
  }
  a {
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    text-decoration: none;
  }
  label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(255, 255, 255, 0.6);
  }
}

@media only screen and (max-width: 769px) {
  .login {
    margin-top: 50px;
    background: #1e1e1e;
    border-radius: 16px;
    padding: 25px 25px;
  }
  h2 {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 50px;
  }
}

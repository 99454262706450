.header {
  background-color: $primary-color;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1400px;
  }
  &_menu {
    display: flex;
    .btn {
      text-transform: uppercase;
    }
    ul {
      display: flex;
      margin-bottom: 0px;
      margin-right: 40px;
      li {
        line-height: 54px;
      }
      a {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: $primary-font-color;
        text-decoration: none;
        padding: 0px 14px;
      }
    }
  }
}

.confirmModal {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background: #141616;
  border-radius: 16px;
  max-width: 410px;
  padding: 32px 24px 24px 24px;
  width: 100%;
  text-align: center;

  p {
    padding-top: 24px;
    padding-bottom: 38px;
  }
  .btns {
    display: flex;
    justify-content: space-between;
  }
  .btn {
    height: 40px !important;
    min-width: 150px;
    text-align: center;
    justify-content: center;
  }
}

.confirmModal_wrapper {
  position: fixed;
  left: 0px;
  top: 0px;
  min-width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.49);
  z-index: 23;
}

@media only screen and (max-width: 769px) {
  .header_logo {
    img {
      height: 40px;
    }
  }

  .header {
    position: relative;
    nav {
      flex-direction: column;
      position: absolute;
      padding-left: 40px;
      top: 94px;
      width: 100%;

      background-color: #202020;
      padding-right: 40px;
      transition: 0.3s;

      display: none;
      min-height: 80vh;
      left: 0;
      .btn {
        margin-top: 50px;
      }
      &.openMobileNav {
        display: block;
        z-index: 4;
      }
      ul {
        flex-direction: column;
      }
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

$primary-font: "Inter", sans-serif;
$secondary-font: "Barlow Condensed", sans-serif;

@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css");
@import "./varaibels";

@import "./components/header";
@import "./components/button";
@import "./components/login";
@import "./components//input";
@import "./pages/register";
@import "./pages/dashboard";
@import "./pages/vpnDetails";
@import "./components/select";

body {
  font-family: $primary-font;
  background-color: $primary-semiLight-color;
  color: $primary-font-color;
}

footer {
  border-top: 1px solid $primary-font-color;
  color: $primary-font-color;
  > div {
    padding: 32px 0px;
  }
}

.homePage {
  min-height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 50px;
  img {
    max-width: 100%;
  }
}

.pointer {
  cursor: pointer;
}
.or {
  position: relative;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  position: relative;
  margin-top: 38px;
  margin-bottom: 38px;
  &::before {
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #fff;
    content: "";
    top: 50%;
    transform: translateY(-50%);
  }
  div {
    position: relative;
    border: 50px;
    width: 40px;
    height: 40px;
    background-color: #1e1e1e;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.text-right {
  text-align: right;
}

.invalid-feedback {
  margin-top: -13px !important;
  margin-bottom: 10px;
  margin-left: 24px;
}

.withoutCursor {
  * {
    cursor: default !important;
  }
}
.mr-2 {
  margin-right: 4px !important;
}

.ml-2 {
  margin-left: 4px !important;
}

.CrateVpnUsername {
  input {
    margin-bottom: 15px !important;
  }
}
.text-md-left {
  text-align: left !important;
}
.loginLinks {
  a,
  label {
    font-size: 14px;
  }
}
.adWrapper {
  img {
    max-width: 80%;
  }
}

.recoverPassword {
  input {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 769px) {
  .adWrapper {
    img {
      max-width: 100%;
    }
  }
  .homePage {
    margin-top: 50px;
    img {
      height: unset;
    }
  }
}

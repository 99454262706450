.select {
  .selectInput {
    width: 273px;
    height: 48px;
    left: 511px;
    top: 445px;
    background: #141616;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 39px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    justify-content: space-between;
    cursor: pointer;
  }
  position: relative;
  .selectOptions {
    box-shadow: 0px 0px 6px #6b93c7;
    border-radius: 18px;
    padding: 12px 24px;
    margin-top: 16px;
    position: absolute;
    background-color: #121212;
    width: 100%;
    z-index: 1;
    max-height: 300px;
    overflow: auto;
    scroll-margin-top: 50px !important;
    &::-webkit-scrollbar {
      scroll-margin-top: 50px !important;

      width: 8px;
      height: 8px;
    }

    /* Set the track color */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    /* Set the thumb color */
    &::-webkit-scrollbar-thumb {
      background-color: #ffffff84;
      border-radius: 6px;
    }

    /* Set the thumb color on hover */
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }
  .option {
    // background: #121212;
    cursor: pointer;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    margin: 12px 0;
  }
}

@media only screen and (max-width: 769px) {
  .select .selectInput {
    margin-right: 0px;
    width: 100%;
  }
}
